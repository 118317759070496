<template>
  <div>
    <head-bar :title="null">
      <template v-slot:left>
        <head-bar-link @click="onBackClick">
          <img src="../../assets/arrow-left.svg" alt="" aria-hidden="true" />
          Terug
        </head-bar-link>
      </template>

      <template v-slot:right>
        <milestone-quick-action
          v-if="milestone"
          :milestone="milestone"
          @milestone-complete="onMilestoneComplete"
        />
      </template>
    </head-bar>

    <app-layout classes="article">
      <div class="p-2 p-md-3" v-if="milestone">
        <h1 class="article__heading mb-2 mb-md-3">
          {{ milestone.translation.title }}
        </h1>

        <milestone-action
          v-if="milestone"
          :milestone="milestone"
          :completed="milestone.completed"
          :completed-media-url="milestone.completed_milestone_media?.default_url"
          @milestone-complete="onMilestoneComplete"
        />
      </div>
    </app-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppLayout from '../AppLayout.vue';
import HeadBar from '../../components/HeadBar.vue';
import HeadBarLink from '../../components/HeadBarLink.vue';
import MilestoneAction from '../../components/MilestoneAction.vue';
import MilestoneQuickAction from '../../components/MilestoneQuickAction.vue';

export default {
  inject: ['$meta'],

  components: {
    MilestoneQuickAction,
    MilestoneAction,
    HeadBarLink,
    HeadBar,
    AppLayout,
  },

  created() {
    this.$store.dispatch('loadMilestones');
  },

  computed: {
    ...mapGetters([
      // 'activeArticle',
      'milestones',
      'milestonesLoadingState',
    ]),

    milestone() {
      if (!this.milestones.length) {
        return null;
      }

      return this.milestones.find((milestone) => (
        milestone.translation.slug === this.$route.params.slug
      ));
    },
  },

  methods: {
    onBackClick() {
      this.$router.go(-1);
    },

    onMilestoneComplete() {
      this.$store.dispatch('loadMilestones');
    },
  },
};
</script>

<style>
.article__media {
  border-radius: .875rem;
  overflow: hidden;
  padding-bottom: 66.666%;
  position: relative;
}

.article__media img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

.article__label {
  color: #887e76;
  margin-bottom: .5rem;
}

.article__heading {
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--purple);
}
</style>
